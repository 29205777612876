@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.1/font/bootstrap-icons.css");
body {
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI",
        "Helvetica Neue", "Roboto", "Oxygen", "Noto Sans", "Ubuntu", "Cantarell",
        "Fira Sans", "Droid Sans", Arial, sans-serif, "Apple Color Emoji",
        "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.form-control::placeholder {
    color: rgb(201, 201, 201) !important;
    font-weight: 300 !important;
}

// * This is for QueryFilter popover bottom component, must impact other bottom-popovers, if it does, modify there
div.popover.show.bs-popover-bottom {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    .arrow {
        left: 77px;
        top: calc((0.5rem + 1px) * -1);
        position: absolute;
        display: block;
        width: 1rem;
        height: 0.5rem;
        margin: 0 0.3rem;
        // transform: translate3d(54px, 0px, 0px) !important;

        &::before,
        &::after {
            position: absolute;
            display: block;
            content: "";
            border-left: 0.5rem solid transparent;
            border-right: 0.5rem solid transparent;
        }

        &::before {
            top: 0;
            border-bottom: 0.5rem solid #aaa;
        }

        &::after {
            top: var(--bs-popover-border-width);
            border-bottom: 0.5rem solid #fff;
        }
    }

    div.popover-inner div {
        padding: 0.5rem 0.5rem;

        button {
            padding: 0.2rem 0.5rem;
            border-radius: 0.3rem;
            transition: background-color 0.2s ease-in-out,
                color 0.2s ease-in-out;
            &.dx-g-bs4-cursor-pointer.dropdown-item {
                background-color: var(--bs-primary);
                color: #fff;
                cursor: pointer;
                margin-bottom: 0;
                font-size: 1rem;
            }
            &.dx-g-bs4-cursor-pointer.dropdown-item:hover {
                background-color: var(--bs-primary-bg-subtle);
                color: var(--bs-primary-text-emphasis);
                cursor: pointer;
            }
            &.dx-g-bs4-cursor-pointer.dropdown-item:not(:last-child) {
                margin-bottom: 0.5rem;
            }
            &.dropdown-item.dx-g-bs4-column-chooser-item.dx-g-bs4-cursor-pointer {
                background-color: #fff;
                color: #000000;
                margin-bottom: 0;
            }
            &.dropdown-item.dx-g-bs4-column-chooser-item.dx-g-bs4-cursor-pointer:hover {
                background-color: rgb(108, 117, 125);
                color: #fff;
            }
        }
    }
}
