#responsive-navbar-nav {
    div a.nav-link.active {
        font-weight: 700 !important;
        text-shadow: rgba(237, 239, 240, 0.61) 1px 1px 2px;
    }
    div.me-auto.navbar-nav div div.dropdown-menu.show {
        z-index: 1021 !important;
    }
}
#nav-dropdown {
    &.dropdown-toggle::after {
        vertical-align: 0.1rem;
    }
    + .dropdown-menu {
        .dropdown-item {
            &:hover {
                background-color: var(--bs-dropdown-bg);
            }
        }
    }
}
